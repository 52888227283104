import * as React from "react"
import { Helmet, HelmetProvider } from 'react-helmet-async'


//On importe les composants
import Header from '../components/header'
import Footer from '../components/footer'

//On importe les images
import Favicon from '../images/favicon.jpg'

//On importe le CSS
import'../css/main.scss'
import '../css/footer.scss'
import '../css/adresse.scss'
import '../css/article.scss'
import '../css/bandeau-accueil.scss'
import '../css/bandeau-contact.scss'
import '../css/bandeau-page.scss'
import '../css/domaines.scss'
import '../css/formulaire.scss'
import '../css/header.scss'
import '../css/presentation.scss'
import '../css/resultats.scss'
import '../css/tableau.scss'

//On importe les SVG
// import Scroll from '../images/scroll.svg'


export default function Honoraires() {



    return (
        <HelmetProvider>
            <Helmet>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
                <title>Honoraires du cabinet Debrabant Avocat</title>
                <meta name="description" content="Retrouvez les réponses à vos questions sur les honoraires pratiqués par le cabinet Debrabant Avocat." />
                <html lang="fr" />
                <link rel="icon" href={Favicon} />


            </Helmet>

            <Header />

            <div className="BackGrey">
                <div className="BandeauMain">

                    <div className="BandeauPageContainer ItemArticle">

                        <h1>HONORAIRES</h1>

                        <div className="ItemArticle">


                            <p align="justify">Le fait de ne traiter que le <b>droit pénal et le contentieux du permis de conduire</b> permet d’avoir une grille tarifaire particulièrement simple et claire.</p>

                            <p align="justify"><b>Je n’accepte pas l’aide juridictionnelle</b>, mais je peux proposer un devis personnalisé en cas de faibles ressources, et un paiement en plusieurs mensualités.</p>

                            <p align="justify">J’accepte en revanche d’intervenir au titre de la protection juridique, votre contrat d’assurance habitation ou automobile comporte souvent une telle garantie qui prend en charge (dans la limite d’un barème) les honoraires d’avocat et d’expert en cas de litige.</p>

                            <p align="justify">La pratique d’un seul domaine d’activité permet de connaître avec suffisamment de précision le temps passé pour chaque type de dossier, et donc de proposer des honoraires forfaitaires.</p>

                            <p align="justify">En d’autres termes, le devis une fois formulé et accepté, aucun supplément, aucune majoration d’honoraires (à l’exception dans certains cas particuliers de frais de déplacements) n’est possible.</p>

                            <p align="justify">Contrairement aux opérateurs téléphoniques, il ne peut donc y avoir de « hors forfait ».</p>

                            <p align="justify">Le client est informé dès le premier rendez vous du budget exact pour chaque procédure.</p>

                            <p align="justify">En cas de difficultés de trésorerie, un paiement en plusieurs fois est possible.</p>

                            <h2>Le 1er rendez vous</h2>

                            <p align="justify"><b>Le premier rendez vous est par principe facturé 96,00 € TTC.</b></p>
                            <p align="justify">Il est ensuite déduit de la facture en cas d’acceptation du devis formulé.</p>

                            <h2>Les procédures</h2>

                            <p align="justify">Les tarifs indiqués ci-après sont indicatifs, une légère modulation est possible pour tenir compte des particularités inhérentes à chaque dossier.</p>
                            <p align="justify">Un droit de plaidoirie d'un montant de 13 € s'ajoute aux honoraires</p>

                            <h3>a) Le droit administratif (récupération d’un permis de conduire annulé, contentieux du permis à points)</h3>

                            <p align="justify">Recours gracieux (saisine directe du Ministère de l’Intérieur) : à partir de 750,00 € TTC</p>
                            <p align="justify">Recours contentieux (procédure devant le Tribunal Administratif) : à partir de 1200,00 € TTC</p>
                            <p align="justify">Recours contentieux avec référé suspension (pour récupérer le permis de conduire en 15 jours maximum) : à partir de 1800,00 € TTC</p>

                            <h3>b) Le droit pénal (lorsque vous êtes convoqué devant le tribunal parce que vous êtes accusé d’avoir commis une infraction)</h3>

                            <p align="justify">Contestation de contravention : à partir de 400,00 € TTC</p>
                            <p align="justify">Procédure Tribunal de Police : à partir de 825,00 € TTC</p>
                            <p align="justify">Procédures Correctionnelles (il en existe 4) :</p>
                            <ul>
                                <li>Composition pénale : 600,00 € TTC</li>
                                <li>Ordonnance pénale délictuelle : à partir de 720,00 € TTC</li>
                                <li>Comparution sur reconnaissance préalable de culpabilité : à partir de 800,00 € TTC</li>
                                <li>Tribunal correctionnel : à partir de 1.200,00 € TTC</li>
                            </ul>

                        </div>

                    </div>

                </div>

            </div>



            <Footer />


        </HelmetProvider >
    )
}
